// extracted by mini-css-extract-plugin
export var container = "page-module--container--nK-Ly";
export var allpage = "page-module--allpage--iyyXx";
export var programkami = "page-module--programkami--4JK57";
export var center = "page-module--center--wbGyC";
export var centerbox = "page-module--centerbox---d7Sa";
export var homeinfo = "page-module--homeinfo--R3zcK";
export var grid3col = "page-module--grid3col--ICoav";
export var containerfas = "page-module--containerfas--M04rE";
export var grid2col = "page-module--grid2col--W6qCo";
export var relaxfont = "page-module--relaxfont--BE2pr";
export var handwrite = "page-module--handwrite--8czh2";
export var contentLinkText = "page-module--content-link-text--YD++d";
export var moveTextUp50 = "page-module--move-text-up50--Vea7q";
export var detailtext = "page-module--detailtext--9S08i";